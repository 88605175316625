
import { Common } from '@/common'
import { Clinic } from '@/modules/clinic'
import { System } from '@/modules/system'
import $app from '@/plugins/modules'
import { Component, Vue } from 'vue-property-decorator'
import { IPlace } from '../types'

@Component
export default class CalendarView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  places: Array<IPlace> = [];
  adding = false;

  async load() {
    try {
      const places: Array<IPlace> = await $app.get('/api/clinics/places');
      places.forEach(place => {
        place.doctors.forEach(doc => {
          doc.processing = false;
        });
      });
      this.places = places;
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
  }

  async append() {
    this.adding = true;
    try {
      const place: IPlace = {
        id: null,
        name: 'NEW PLACE',
        order: 0,
        color: '',
        doctors: [],
        docIndex: {}
      }
      await $app.post('/api/clinics/places', place);
      await this.load();
    } catch (error) {
      $app.pushError(error);
    }
    this.adding = false;
  }

  created() {
    this.load();
  }
}
